import * as React from "react"

import { DefaultLayout } from "../layouts";
import { SEO, Line, Call } from "../components";

import styles from './home/index.module.scss';
// import { Header, Basics, Online, Grow, Resume, Form, FAQs } from './home/components'
import { Header, Basics, Online, Grow, Resume, Form, FAQs } from '../page_components/home'

import img from '../assets/images/logo2.png'

// markup
const IndexPage = () => {
    return (
        <DefaultLayout>
            <SEO title="Calendab · Tu agenda online" />
            <div className={styles.container}>
                <div className={styles.content}>
                    <Header />
                    <Line />
                    {/* <span className={styles.line}></span> */}
                    <Basics />
                    <Line />
                    <Online />
                    <Call />
                    <Grow />
                    <Line />
                    <Resume />
                    <Call version={1} />
                    <Form />
                    <Line />
                    <FAQs />
                </div>
            </div>
        </DefaultLayout>
    )
}
    
export default IndexPage
    