import * as React from "react"
import { Link } from 'gatsby'

import { Container, ContactForm } from '../../../components'

import styles from './form.module.scss';

import contact from '../../../assets/images/contact.svg'

// markup
export const Form = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    Contacta con nosotros
                </h3>
                <div className={styles.content}>
                    <div className={styles.body}>
                        <div className="columns is-desktop">
                            <div className="column">
                                <div className={styles.subtitle}>¿Tienes alguna duda?</div>
                                <div className={styles.text}>En los FAQs resolvemos las más comunes, pero si aún asi tienes dudas... <br/>No te preocupes, rellena este formulario explicando y nos ponemos en contacto contigo. <br/> <br/> También puedes enviar un correo: <a href="mailto:hola@calendab.com?subject=Hola!" className={styles.link}>hola@calendab.com</a></div>
                                <img src={contact} style={{'--width': '350px'}} className={styles.img} width="350px" height="280px" alt="Contacta con nosotros" />
                            </div>
                            <div className="column">
                                <ContactForm />
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

// const query = graphql`
//     query Grow {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `