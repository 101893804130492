import * as React from "react"

import { Container } from '../../../components'

import styles from './faqs.module.scss';

// markup
export const FAQs = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    FAQs
                </h3>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div className={styles.text}>Preguntas frecuentes.</div>
                    </div>
                    <div className={styles.body}>
                        <div className="columns is-tablet is-multiline">
                            <div className="column is-half">
                                <div className={styles.faq}>
                                    <div className={styles.faq_q}>¿Para quién es Calendab?</div>
                                    <div className={styles.faq_a}>Calendab ha sido diseñado para ayudar a pequeños negocios que necesiten digitalizar su agenda de manera sencilla.</div>
                                </div>
                            </div>
                            <div className="column is-half">
                                <div className={styles.faq}>
                                    <div className={styles.faq_q}>¿El plan gratuito es para siempre?</div>
                                    <div className={styles.faq_a}>Sí, el plan gratuito lo será para siempre. Podrás usarlo todo lo que quieras, eso si... con los límites gratuitos.</div>
                                </div>
                            </div>
                            <div className="column is-half">
                                <div className={styles.faq}>
                                    <div className={styles.faq_q}>¿Puedo cambiar de plan?</div>
                                    <div className={styles.faq_a}>
                                        Podrás cambiar de plan en el momento que quieras. <br/> 
                                        Mejorarlo será muy fácil; simplemente tendrás que escoger el nuevo plan en "Manager" - "Facturación". <br/>
                                        Si quieres cambiar a un plan más barato, la cosa se complica debido a los límites (número de clientes...). Deberás ponerte en contacto con nosotros.
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half">
                                <div className={styles.faq}>
                                    <div className={styles.faq_q}>¿Puedo cancelar mi suscripción en cualquier momento?</div>
                                    <div className={styles.faq_a}>
                                        Podrás cancelar tu suscripción en el momento que quieras. <br/> 
                                        La cuenta se eliminará al finalizar el ciclo de facturación ya pagado. Esto quiere decir que podrás seguir usando Calendab hasta el fin de tu suscripción.
                                    </div>
                                </div>
                            </div>
                            <div className="column is-half">
                                <div className={styles.faq}>
                                    <div className={styles.faq_q}>¿Es fácil de usar?</div>
                                    <div className={styles.faq_a}>Desde lo básico a lo más avanzado, Calendab es intuitivo y lo más simple posible. Siempre puedes contactar con nostros y te ayudamos en lo que sea.</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

// const query = graphql`
//     query Grow {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `