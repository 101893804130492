import * as React from "react"
import { Link, useStaticQuery, graphql  } from "gatsby"

import { Container, Button } from '../../../components'

import styles from './header.module.scss';

// markup
export const Header = () => {

    const { site } = useStaticQuery(query)
    const {
        appUrl
    } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h1 className={styles.title}>
                    <span>Digitaliza.</span>
                    <span>Automatiza.</span>
                    <span>Crece.</span>
                </h1>
                <div className={styles.action}>
                    <Link to={`${appUrl}/signup`} >
                        <Button text={"Empieza gratis"} fullwidthMobile large />
                    </Link>
                    <Link to={`/contacto`} >
                        <Button text={"¿Tienes dudas?"} fullwidthMobile secondary large />
                    </Link>
                </div>
                <h2 className={styles.text}>Despide a la agenda tradicional, digitaliza tu negocio. Ahorra tiempo buscando la hora perfecta para tus clientes, que lo hagan ellos mismos. Nuestra plataforma te ayuda a centrarte en tu trabajo.</h2>
            </Container>
        </div>
    )
}
    

const query = graphql`
    query Header {
        site {
            siteMetadata {
                appUrl: appUrl
            }
        }
    }
`