import * as React from "react"
import { useStaticQuery, graphql, Link } from 'gatsby'

import { Container, Plan } from '../../../components'

import styles from './grow.module.scss';

// markup
export const Grow = () => {

    const { site } = useStaticQuery(query)
    const {
        appUrl
    } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    Nos Adaptamos
                </h3>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div>
                            <p className={styles.text}>
                                En Calendab ofrecemos diferentes planes para diferentes momentos de tu negocio, siempre puedes empezar por el gratuito y cambiar con tus necesidades.
                            </p>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.section}>
                            <div className="columns is-multiline">
                                <div className="column is-half-tablet is-one-quarter-desktop">
                                    <div className={styles.plan}>
                                        <Plan 
                                            title={"Free"} 
                                            desc={"Plan grauito para siempre"} 
                                            price={['0', '0']} 
                                            url={`${appUrl}/signup`} 
                                            features={["50 citas", "1 Agenda", "Acceso desde cualquier dispositivo", "Reservas online"]}
                                        />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-quarter-desktop">
                                    <div className={styles.plan}>
                                        <Plan 
                                            title={"Starter"} 
                                            desc={"Digitaliza tu negocio"} 
                                            price={['19.99', '199.99']} 
                                            url={`${appUrl}/signup`} 
                                            features={["350 citas", "1 Agenda", "Acceso desde cualquier dispositivo", "Reservas online", "Recordatorios"]}
                                        />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-quarter-desktop">
                                    <div className={styles.plan}>
                                        <Plan 
                                            title={"Standard"} 
                                            desc={"¿Estas creciendo?"} 
                                            price={['39.99', '399.99']} 
                                            url={`${appUrl}/signup`} 
                                            features={["1100 citas", "1 Agenda", "Acceso desde cualquier dispositivo", "Reservas online", "Recordatorios"]}
                                        />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-quarter-desktop">
                                    <div className={styles.plan}>
                                        <Plan 
                                            title={"Pro"} 
                                            desc={"La cosa se pone seria"} 
                                            price={['99.99', '999.99']} 
                                            url={`${appUrl}/signup`} 
                                            features={["2000 citas", "2 Agenda", "Acceso desde cualquier dispositivo", "Reservas online", "Recordatorios"]}
                                        />
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className={styles.footer}>
                            <p className={styles.text}>¿No te encaja ninguno? Ponte en <Link to={"/contacto"} className={styles.link}>contacto</Link> con nosotros y lo resolvemos.</p>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

const query = graphql`
    query Grow {
        site {
            siteMetadata {
                appUrl: appUrl
            }
        }
    }
`