import * as React from "react"

import { Container, Window } from '../../../components'

import styles from './online.module.scss';

import online_wireframe from '../../../assets/images/online_wireframe.svg'
import free_time from '../../../assets/images/free_time.svg'
import mobile from '../../../assets/images/mobile.svg'
import always from '../../../assets/images/24_7.svg'

// markup
export const Online = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    Agenda Online
                </h3>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div>
                            <p className={styles.text}>
                                ¿Has soñado alguna vez con no tener que dejar de trabajar para coger el teléfono? ¿Poder seguir haciendo tus tareas sin perder tiempo en gestiones con tus clientes? ¿Tener nuevas reservas mientras duermes?
                            </p>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className={styles.section}>
                            <div className="columns is-desktop">
                                <div className="column ">
                                    {/* <img src={img} /> */}
                                    <div className={styles.windows}>
                                        <div className={styles.window}>
                                            <Window title="calendab.com" >
                                                <img src={online_wireframe} width="480px" height="206px" alt="Citas online" />
                                            </Window>
                                        </div>
                                    </div>
                                </div>
                                <div className="column ">
                                    <div className={styles.item}>
                                        <h4 className={styles.subtitle}>Citas Online</h4>
                                        <div>
                                            <p className={styles.text}>Da acceso a tus clientes, que ellos reserven la hora que mejor les va de manera totalmente intuitiva y autónoma.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className={styles.section}>
                            <div className="columns is-desktop is-hidden-touch">
                                <div className="column ">
                                    <div className={`${styles.item} ${styles.item__left}`}>
                                        <h4 className={styles.subtitle}>Ahorra tiempo</h4>
                                        <div>
                                            <p className={styles.text}>¿Cuanto más hubieras facturado trabajando todas las horas que has dedicado al teléfono?</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column ">
                                    <div className={`${styles.windows} ${styles.windows__right}`}>
                                        <img src={free_time} className={styles.img} width="350px" height="280px" alt="Ahorra tiempo" />
                                    </div>
                                </div>
                            </div>    
                            <div className="columns is-desktop is-hidden-desktop">
                                <div className="column ">
                                    <div className={`${styles.windows} ${styles.windows__right}`}>
                                        <img src={free_time} className={styles.img} width="350px" height="280px" alt="Ahorra tiempo" />
                                    </div>
                                </div>
                                <div className="column ">
                                    <div className={`${styles.item} ${styles.item__left}`}>
                                        <h4 className={styles.subtitle}>Ahorra tiempo</h4>
                                        <div>
                                            <p className={styles.text}>¿Cuanto más hubieras facturado trabajando todas las horas que has dedicado al teléfono?</p>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className={styles.section}>
                            <div className="columns is-desktop">
                                <div className="column ">
                                    {/* <img src={img} /> */}
                                    <div className={styles.windows}>
                                        <img src={mobile} style={{'--width': '350px'}} className={styles.img} width="350px" height="280px" alt="Desde el móvil, sin descargas" />
                                        {/* <div className={styles.window}>
                                            <Window title="calendab.com" >
                                                <img src={online_wireframe} />
                                            </Window>
                                        </div> */}
                                    </div>
                                </div>
                                <div className="column ">
                                    <div className={styles.item}>
                                        <h4 className={styles.subtitle}>Desde el móvil, sin descargas</h4>
                                        <div>
                                            <p className={styles.text}>Calendab está disponible desde cualquier dispositivo, y sin descargas, simplemente un enlace y tus clientes ya estarán reservando hora.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                        <div className={styles.section}>
                            <div className="columns is-desktop is-hidden-touch">
                                <div className="column ">
                                    <div className={`${styles.item} ${styles.item__left}`}>
                                        <h4 className={styles.subtitle}>Acceso 24/7</h4>
                                        <div>
                                            <p className={styles.text}>Tus clientes podrán acceder a cualquier hora, para pedirte nueva cita o simplemente mirar cuando tienen la próxima.</p>
                                        </div>
                                    </div>
                                </div>
                                <div className="column ">
                                    <div className={`${styles.windows} ${styles.windows__right}`}>
                                        <img src={always} style={{'--width': '350px'}} className={styles.img} width="350px" height="280px" alt="Acceso 24/7" />
                                    </div>
                                </div>
                            </div>    
                            <div className="columns is-desktop is-hidden-desktop">
                                <div className="column ">
                                    <div className={`${styles.windows} ${styles.windows__right}`}>
                                        <img src={always} style={{'--width': '350px'}} className={styles.img} width="350px" height="280px" alt="Acceso 24/7" />
                                    </div>
                                </div>
                                <div className="column ">
                                    <div className={`${styles.item} ${styles.item__left}`}>
                                        <h4 className={styles.subtitle}>Acceso 24/7</h4>
                                        <div>
                                            <p className={styles.text}>Tus clientes podrán acceder a cualquier hora, para pedirte nueva cita o simplemente mirar cuando tienen la próxima.</p>
                                        </div>
                                    </div>
                                </div>
                            </div>    
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

// const query = graphql`
//     query Header {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `