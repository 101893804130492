import * as React from "react"

import { Container, Window } from '../../../components'

import styles from './basics.module.scss';

import dashboard_wireframe from '../../../assets/images/dashboard_wireframe.svg'
import calendar_wireframe from '../../../assets/images/calendar_wireframe.svg'

// markup
export const Basics = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    ¿Qué es Calendab?
                </h3>
                <div className={styles.content}>
                    <div className={styles.header}>
                        <div>
                            <p className={styles.text}>
                                Calendab es tu agenda de toda la vida pero digital. Hemos desarrollado, con tecnologías de vanguardia, la herramienta definitiva para tu negocio. Tendrás el control total desde cualquier dispositivo, lugar y momento. ¿Quieres darle un último vistazo en la cama a lo que viene mañana? Sin problemas, saca tu móvil y hazlo.
                            </p>
                        </div>
                    </div>
                    <div className={styles.body}>
                        <div className="columns is-desktop">
                            <div className="column ">
                                {/* <img src={img} /> */}
                                <div className={styles.windows}>
                                    <div className={styles.window}>
                                        <Window title="calendab.com" >
                                            <img src={dashboard_wireframe} width="480px" height="206px" alt="Panel de administración" />
                                        </Window>
                                    </div>
                                    <div className={styles.window}>
                                        <Window title="calendab.com" >
                                            <img src={calendar_wireframe} width="480px" height="206px" alt="Calendario básico" />
                                        </Window>
                                    </div>
                                </div>
                            </div>
                            <div className="column ">
                                <div className={styles.item}>
                                    <h4 className={styles.subtitle}>Toma el Control de tu Agenda</h4>
                                    <div>
                                        <p className={styles.text}>Da el paso, digitalízate... elimina la agenda tradicional, abraza las facilidades y ventajas de lo digital. </p>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <h4 className={styles.subtitle}>Gestiona tus Clientes</h4>
                                    <div>
                                        <p className={styles.text}>No pierdas detalle, apúntate la información importante de tus clientes. Dales acceso a tu agenda para que se pidan hora ellos mismos.</p>
                                    </div>
                                </div>
                                <div className={styles.item}>
                                    <h4 className={styles.subtitle}>Tu Negocio</h4>
                                    <div>
                                        <p className={styles.text}>Consulta los datos de tu negocio en cualquier momento. Mira las evoluciones mensuales y semanales del año, actúa en consecuencia.</p>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

// const query = graphql`
//     query Header {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `