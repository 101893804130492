import * as React from "react"
import { Link } from 'gatsby'

import { Container, Item } from '../../../components'

import styles from './resume.module.scss';

import control from '../../../assets/images/control.png';
import allday from '../../../assets/images/allday.png';
import calendar from '../../../assets/images/calendar.png';
import devices from '../../../assets/images/devices.png';
import emails from '../../../assets/images/emails.png';
import phone from '../../../assets/images/phone.png';

// markup
export const Resume = () => {

    // const { site } = useStaticQuery(query)
    // const {
    //     appUrl
    // } = site.siteMetadata

    return (
        <div className={styles.core}>
            <Container>
                <h3 className={styles.title}>
                    Resumiendo...
                </h3>
                <div className={styles.content}>
                    {/* <div className={styles.header}>
                        <div>
                            <p className={styles.text}>
                                En Calendab ofrecemos diferentes planes para diferentes momentos de tu negocio, siempre puedes empezar por el gratuito y cambiar con tus necesidades.
                            </p>
                        </div>
                    </div> */}
                    <div className={styles.body}>
                        <div className={styles.section}>
                            <div className="columns is-multiline is-centered">
                                <div className="column is-half-tablet is-one-third-desktop">
                                    <div className={styles.item}>
                                        <Item alt={"Toma el control"} title={"Toma el control"} desc={"Digitalízate, gestiona tus clientes y mira como crece tu negocio."} icon={control} />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-third-desktop">
                                    <div className={styles.item}>
                                        <Item alt={"Citas Online"} title={"Citas Online"} desc={"Tus clientes reservan, tu trabajas."} icon={calendar} />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-third-desktop">
                                    <div className={styles.item}>
                                        <Item alt={"Ahorra Tiempo"} title={"Ahorra Tiempo"} desc={"Deja el teléfono de lado, céntrate en tu trabajo."} icon={phone} />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-third-desktop">
                                    <div className={styles.item}>
                                        <Item alt={"Desde el móvil"} title={"Desde el móvil"} desc={"Cualquier dispositivo y sin descargas. Un enlace y a disfrutar."} icon={devices} />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-third-desktop">
                                    <div className={styles.item}>
                                        <Item alt={"Recordatorios"} title={"Recordatorios"} desc={"Envia recordatorios a tus clientes sobre sus citas."} icon={emails} />
                                    </div>
                                </div>
                                <div className="column is-half-tablet is-one-third-desktop">
                                    <div className={styles.item}>
                                        <Item alt={"Acceso 24/7"} title={"Acceso 24/7"} desc={"A cualquier hora, tu agenda siempre disponible."} icon={allday} />
                                    </div>
                                </div>
                            </div>    
                        </div>
                        {/* <div className={styles.footer}>
                            <p className={styles.text}>¿No te encaja ninguno? Ponte en <Link to={"/contacto"} className={styles.link}>contacto</Link> con nosotros y lo resolvemos.</p>
                        </div> */}
                    </div>
                </div>
            </Container>
        </div>
    )
}
    

// const query = graphql`
//     query Grow {
//         site {
//             siteMetadata {
//                 appUrl: appUrl
//             }
//         }
//     }
// `